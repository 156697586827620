body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
}


h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

ol ol,

ul ul,

ol ul,

ul ol {

  margin: 0;
  padding: 0;

}

a {
  text-decoration: none;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  max-width: 1520px;
}

@media only screen and (max-width: 1520px) {
  .container {
    max-width: 1200px;
  }
}

html {
  scroll-behavior: auto;
}