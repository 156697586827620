.header{
    position: relative;
    padding: 2.875rem 0;
   .flex{
       align-items: center;
   }
}
.header::after{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background: transparent linear-gradient(180deg, #003F63 0%, #000D14 100%) 0% 0% no-repeat padding-box;
    height:8.75rem;
    z-index: -1;
}
.search_box{ 
    width: 25rem;
    height: 3.188rem;
    border-radius: 0.375rem;
    background: #145174;
    input{
        color:#fff;
    }
    input:focus{
        color: #fff;
    }
}
.search_box{
    padding:0 1.125rem;
    margin-right: 10px;
    .flex{
        align-items: center;
        flex-wrap: inherit;
    }
    .form-control{
        background: transparent !important;
        border: none;
    }
    input::placeholder{
        color: #C6CCCE;
    }
}
.avtar{
    width: 2.625rem;
    height: 2.625rem;
    border-radius: 2.625rem;
    overflow: hidden;
    // border: 1px solid #C6CCCE;
    img{
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}
.notification{
    margin: 0 1.5rem;
}
.w50{
    width: 50%;
}
.sl-menu{
    margin-left: 3.125rem;
    a{
        padding: 0 1.563rem;
        font-size: 1.125rem;
        color: #C6CCCE;
        font-family: 'Montserrat',sans-serif;
    }
    a.active{
        color: #0089CC;
        font-family: 'Montserrat',sans-serif;
        font-weight: bold;
    }
}
.toggle_menu{
    display: none;
}
.sidebar-menu{
    .sidebar-profile{
        background: #0089CC;
        text-align: center;
        padding-top: 25px;
        padding-bottom:15px;
        .pro_pic{
            width: 80px;
            height: 80px;
            border-radius: 5rem;
            border:5px solid #569ec3;
            margin: 0 auto;
            overflow: hidden;
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
        h4{
            font-size: 1.25rem;
            color: #fff;
            font-family: 'Montserrat',sans-serif;
            line-height: 26px;
        }
        p{
            font-size: 14px;
            color: #fff;
            font-family: 'Montserrat',sans-serif;
            line-height: 16px;
        }
        a{
            font-size: 14px;
            color: #fff;
            font-family: 'Montserrat',sans-serif;
            line-height: 16px;
            display: inline-block;
            
        }
    }
}
.profile{
    .dropdown-toggle{
        background: transparent;
        border: none;
    }
    .dropdown-toggle:hover,.dropdown-toggle:focus{
        background: transparent;
        border: none;
        outline: none;
        box-shadow: none !important;
    }
    .btn-check:active+.btn-secondary, .btn-check:checked+.btn-secondary, .btn-secondary.active, .btn-secondary:active, .show>.btn-secondary.dropdown-toggle{
        background: transparent;
        border: none;
    }
    .dropdown-menu{
        left: inherit !important;
        transform: translate3d(0px, 63px, 0px) !important;
        right: -10px;
        background: #E7EFF3 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 60px #00000029;
        border: 1px solid #D5D5D5;
        width: 18.625rem;
        padding: 18px;
        border-radius:8px;
        
        a{
            display: block;
            padding: 1rem 10px;
            border-bottom: 1px solid #CCCCCC;
            cursor: pointer;
            display: block;
            .sl-icon{
                margin-right: 5px;
                svg{
                    max-width: 22px;
                    max-height: 22px;
                    .a{
                        fill: #0089cc !important;
                    }
                }
            }
        }
        :last-child{
            border-bottom: none;
        }
        a:hover{
            background: #fff;
        }
    }
    .dropdown-item{
        font-size: 1.125rem;
    }
    .dropdown-toggle::after{
        content: '';
        background: url(../../assets/img/down-arrow-white.svg)no-repeat;
        position: absolute;
        right: -15px;
        border: none;
        width: 20px;
        height: 20px;
        top: 30%;
    }
}
.side_navbar{
    a{
        svg{
            .a{
                fill:#0089CC !important;
            }
        }
    }
}
.sidebar-menu{
    display: none;
}
.overlay.open{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .3);
    z-index: 99;
}
.sidebar-menu{
    width: 70%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    background: #fff;
    z-index: 100;
    box-shadow: 0 0 10px #ccc;
}
.sidebar-menu{
    transform: translateX(-100%);
    transition: .5s;
    z-index: 100;
    
    .side_navbar{
        a{
            display: block;
            padding:10px 15px;
            color: #505050;
            font-size: 16px;
            background: #ECEFF1;
            border-bottom: 1px solid #fff;
            .sl-icon{
                display: inline-block;
                svg{
                    width: 20px;
                    height: 20px;
                    margin-right: 8px;
                }
            }
        }
    }
}
.noti_box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #CCCCCC;
    a{
        border-bottom: none !important;
    }
}
.noti_box:hover{
    background: #fff;
    
}
.notification{
    position: relative;
}


    // background-color: red;
    // border: 1px solid red !important;
    // color: white !important;
    // font-size: 12px;
    // padding-left: 5px;
    // padding-right: 5px;
    // border-radius: 100%;
    // position: absolute;
    // top:-7px;
    // right: -8px;
.blink {
    animation: blink 1s steps(5, start) infinite;
    -webkit-animation: blink 1s steps(5, start) infinite;
    }
    @keyframes blink {
    to {
        visibility: hidden;
    }
    }
    @-webkit-keyframes blink {
    to {
        visibility: hidden;
    }
}


@media (max-width:1520px){
    
}
@media (min-width:768px) and (max-width:1024px){
    
    .sidebar-menu{
        transform: translateX(-100%);
        transition: .5s;
        z-index: 100;
        width: 50%;
    }
    .sidebar-menu {
        display: block;
    }
    .brand{
        margin-left: 20px;
        img{
            max-width: 120px;
        }
    }
    .toggle_menu {
        display: block;
    }
    .sl-menu {
        display: none;
    }
    .tabs_grid {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }

    
}


@media (max-width:767px){


.sidebar-menu{
    display: block;
}
    .upcoming_appointments{
        .slick-track{
            margin-left: 0;
        }
    }

    .header::after{
        height: 8rem;
    }
    .brand{
        img{
            max-width: 140px;
        }
    }
.search_box{
    width: 100%;
    margin-right: 0;
    margin-top:25px;
    background: #fff;
    box-shadow: 0px 5px 40px rgba(93, 117, 151,.15);
    input {
        color:#000;
        
    }
    input:focus{
        color: #000;
    }
}
.notficationBox{
    position: absolute;
    top: 10px;
    right:25px;
    max-width: 40% !important;
    .flex{
        justify-content: flex-end;
    }
}

.search-item{
    order: 1;
}
    .search-flex{
        flex-wrap: wrap;
    }


    .toggle_menu{
        display: block;
        margin-top: -8px;
    }
    .header{
        padding-top:15px;
        padding-bottom: 1.5rem;
        .brand{
            margin-left: 10px;
            text-align: center;
            margin-bottom: 10px;
        }
    }
    .sl-menu{
        display: none;
    }
    .main-flex{
        flex-wrap: wrap;
        .item{
            flex: 0 0 100%;
            max-width: 100%;
            width: 100%;
        }
    }
    .item.menu_box{
        flex: 0 0 50%;
        max-width: 50%;
        width: 100%;
    }

}



@media (max-width:375px){
    .brand img{
        max-width: 110px;
    }

}