.refun_dialog_modal{
    max-width: 52.438rem;
    width: 100%;
    text-align: center;
    .modal-body{
        padding: 4.688rem;
    }
    .modal_content{
        max-width: 21.375rem;
        margin: 0 auto;
        button{
            display: block;
            margin: 10px 0;
        }
        p{
            font-size: 15px;
            color: #505050;
            margin-top: 5px;
        }
    }
}