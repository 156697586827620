@mixin familyBook {
    font-family: 'Campton Book';
}
@mixin familyThin {
    font-family: 'Campton Thin';
}
@mixin familyLight {
    font-family: 'Campton Light';
}
@mixin familyMedium {
    font-family: 'Campton Medium';
}
@mixin familySemiBold {
    font-family: 'Campton SemiBold';
}
@mixin familyBold {
    font-family: 'Campton Bold';
}
@mixin familyExtraBold {
    font-family: 'Campton ExtraBold';
}
@mixin familyBlack {
    font-family: 'Campton Black';
}
.movedAppointDetail{
.sl-main-box{
    padding: 0 23px;
}
.sl-main-heading{
    border-bottom: 1px solid #CCCCCC;
}
.lawyer__info{
    border-top: 1px solid #CCCCCC;
    padding-top:15px;
    .book_box{
        margin: 0;
        padding: 31px;
    }
}
.appoint_Detail_box{
    padding: 18px 25px;
    margin-bottom: 1rem;
    p{
        font-size: 15px;
        color: #505050;
        line-height: 18px;
    }

.time-flex-box{
    margin-top: 28px;
    label{
        color: #0089CC;
        font-size: 16px;
    }
    p{
        font-size: 16px;
        color: #505050;
    }
}
.ar-buttons{
    button{
        width: 10.563rem;
        height: 3.188rem;
        border-radius: 6px;
        border: none;
        font-size: 1.25rem;
        color: #fff;
        margin: 0 10px;
        background: #0089CC;
    }
}
}


}

.all-appointment{
    background: #fff;
    border-radius: 0.625rem;
    padding-top: 2.5rem;
    border: 1px solid #CCCCCC;
    margin-bottom: 3.938rem;
    padding-bottom: 15px;
    .nav-tabs{
        justify-content: center;
        border: 1px solid #ccc;
        width: 41.938rem;
        height: 45px;
        border-radius:6px;
        margin: 0 auto;
        position: relative;
        .nav-item{
          flex: 0 0 50%;
          max-width: 50%;
          width: 100%;
          text-align: center;
          .nav-link{
              border: none;
              cursor: pointer;
          }
          .nav-link.active{
              color: #48C2FE;
          }
        }
    }
    .nav-tabs:after{
        content: '';
        position: absolute;
        width: 1px;
        height: 100%;
        top: 0;
        bottom: 0;
        background: #CCCCCC;
    }
    .nav-tabs .nav-link{
        font-size: 18px;
        @include familyBold();
        color: #CCCCCC;
        margin-bottom: 0;
    }
}
.sub-tabs{
    margin-top: 2.5rem;
    padding-left: 20px;
    padding-right: 20px;
    .nav.nav-tabs{
        width:100%;
        border: none;
        border-bottom: 1px solid #ccc;
        border-radius: 0;
       
        .nav-item{
            flex: 0 0 25%;
            max-width: 25%;
            .nav-link{
                @include familyBook();
            }
            .nav-link.active{
                color: #505050;
                @include familyBold();
                position: relative;
            }
            .nav-link.active:after{
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom:-4px;
                height: 3px;
                width: 100%;
                background:#0089CC;
            }
        }
    }
    .nav.nav-tabs:after{
        display: none;
    }
}

.book_box{
    width: 23.313rem;
    border: 1px solid #DEDEDE;
    border-radius: 10px;
    padding: 1.125rem;
    margin-bottom: 20px;
    .lawyer_pic{
        width: 4.125rem;
        height: 4.125rem;
        border-radius: 4.125rem;
        overflow: hidden;
        margin-right: 15px;
        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    h4{
        font-size: 1.25rem;
        @include familyBold();
        color: #505050;
        padding-bottom: 3px;
    }
    span{
        font-size: 15px;
        color: #505050;
        display: inline-block;
    }
    p{
        font-size: 15px;
        color: #505050;
        padding: 3px 0;
    }
    .sl-view-btn{
        background:#0089CC;
        width: 10.625rem;
        height:35px;
        display: inline-block;
        color: #fff;
        text-align: center;
        line-height:35px;
        border-radius: 6px;
        font-size: 16px;
        @include familyLight();
        margin-top: 7px;
    }
    .sl-price-btn{
        background: #DCEDF5;
        color: #0089CC;
        @include familyBold();
        padding: 6px 10px;
        border-radius: 4px;
        border: none;
    }
}
.tabs_grid{
    margin-top: 2.625rem;
    justify-content: space-between;
    padding-left: 7rem;
    padding-right: 7rem;
    flex-wrap: wrap;
}
.view_booking_details{
  .view_box{
      .flex{
          align-items: flex-start;
      }
      .heading{
        padding-bottom: 17px;
        align-items: center;
      }
      h3{
          font-size: 1.5rem;
          @include familyBold();
          color: #003F63;
         
      }
      .sl-back-btn{
          width: 88px;
          height: 34px;
          border: 1px solid #DCDCDC;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          color: #0089CC;
      }
  } 
  .sl-price-btn{
      margin-left: 30px;
  } 
  .sl-cancel-btn{
      width: 21.25rem;
      height: 3.188rem;
      margin-top: 2.563rem;
      background: #0089CC;
      border-radius: 6px;
      border: none;
      font-size: 1.25rem;
      @include familyLight();
      color: #fff;
      text-transform: uppercase;
      margin-bottom: 1.875rem;
  }
}

.view_detail_box{
    .book_box{
    border: none;
    width: 100%;
}
.exp_box{
    p{
        display: inline-block;
        padding-right:12px;
        font-size: 16px;
    }
}
.book_box {
p{
    font-size: 16px;   
}
}
}
.fresh-appt{
    font-size: 16px;
    color: #505050;
    @include familyLight();
    white-space: nowrap;
    display: inline-block;
}

.client-details{
    padding: 1.5rem;
    h3{
        font-size: 1.5rem;
        color: #6B6B6B;
        @include familyBold();
    }
    .client_info{
        margin-top: 10px;
        label{
            font-size: 16px;
            @include familyBold();
            color: #0089CC;
        }
        p{
            font-size: 16px;
            color: #505050;
            @include familyLight();
        }
        
        .item{
            flex: 0 0 20%;
            max-width: 20%;
        }
    }
}
.rele_Doc{
    margin-top: 2.5rem;
    h3{
        font-size: 1.5rem;
        color: #6B6B6B;
        @include familyBold();
        padding-bottom: 20px;
    }
}
.all_rele_doc{
    .doc-name{
        width: 7rem;
        text-align: center;
        p{
            font-size: 14px;
            color: #8BA5AF;
            line-height: 16px;
        }
    }
}
.completed{
    .lawyer_ratings{
        margin-top: 2.688rem;
        h4{
            font-size: 1.5rem;
            @include familyBold();
            color: #6B6B6B;
    
        }
        .sl-icon{
            display: inline-block;
            svg{
                width: 25px;
                height: 25px;
                margin: 0 2px;
                path{
                    fill: #E6E5E2 !important;
                }
            }
        }
        .ratings_star{
            padding: 0 2.625rem;
            padding-left: 16px;
    
        }
    }
    .sl-submit-btn{
        border:1px solid #CDE5F1;
        font-size: 14px;
        @include familyBook();
        color: #0089CC;
        padding: 6px 11px;
        border-radius:6px;
        background: #fff;
        box-shadow: none;
    }
}
.appt-cancelled{
    text-align: center;
    padding: 2.625rem 0;
    p{
        font-size: 1.125rem;
        color: #E83743;
        @include familyMedium();
    }
}

.follow-up-tabs{
    .sub-tabs .nav.nav-tabs .nav-item {
        flex: 0 0 20%;
        max-width:20%;
    }
}
.follow-UpFee{
    border-top: 1px solid #D2D2D2;
    border-bottom: 1px solid #D2D2D2;
    padding: 25px;
    label{
        font-size: 16px;
        @include familyBold();
        color: #0089CC;
    }
    p{
        font-size: 16px;
        color: #505050;
    }
    .flex{
        align-items: center;
        label{
            margin-right:23px;
        }
        .doc-name{
            display: flex;
            align-items: center;
            p{
                margin-left:10px;
                font-size: 14px;
                color: #8BA5AF;
                line-height: 16px;
            }
        }
    }
    .followUp-info{
        margin-bottom: 20px;
    }
}
.email_item{
    flex: 0 0 60% !important;
    max-width: 60% !important;
    padding-right: 10px;
}



@media (max-width:767px){
    .movedAppointDetail .appoint_Detail_box{
        padding: 18px 0px;
    }
    .time-flex-box{
        flex-wrap: wrap;
        .flex-item{
            flex: 0 0 100%;
            max-width: 100%;
            width: 100%;
            .ar-buttons{
                margin-top: 30px;
                button{
                    width: 100%;
                    margin: 10px 0;
                }
            }
        }
    }
    .movedAppointDetail .lawyer__info .book_box{
        padding: 20px 0;
    }
    .follow-UpFee{
        padding: 15px 0;
    }
    .flex.client_info{
        flex-wrap: wrap;
    }
    .client-details .client_info .item{
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 10px;
    }
    .client-details{
        padding: 1.5rem 0;
        position: relative;
        .fresh-appt{
          position: absolute;
          top:15px;
          right: 0;
        }
    }
    .view_booking_details .view_box .flex{
        flex-wrap: wrap;
        .item{
            flex: 0 0 100%;
            max-width: 100%;
            width: 100%;
        }
    }
    .view_booking_details .sl-price-btn {
        margin-left: 0;
    }
    .book_box .lawyer_pic{
        margin-bottom: 10px;
    }
}