.page-not-found {

    .not-found {
        width: 500px;
        height: 500px;
        // box-shadow: 0 0 10px #ccc;
        margin: 0 auto;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #fff;
        text-align: center;

        h3 {
            font-size: 2.125rem;
            font-family: 'Nunito', sans-serif;
            font-weight: 800;
            color: #3B3B3B;
            padding: 1.5rem !important;
        }

        p {
            font-size: 16px;
            line-height: 20px;
        }
    }

    .goToHome {
        width: 8.438rem;
        height: 2.938rem;
        border-radius: 0.313rem;
        background: #015BBB;
        border: none;
        color: #fff;
        font-size: 1.125rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }
}