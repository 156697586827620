@font-face {
  font-family: 'Campton Book';
  font-style: normal;
  font-weight: normal;
  src: local('Campton Book'), url('./assets/fonts/CamptonBook.woff') format('woff');
}


@font-face {
  font-family: 'Campton Book Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Campton Book Italic'), url('./assets/fonts/CamptonBookItalic.woff') format('woff');
}


@font-face {
  font-family: 'Campton Thin';
  font-style: normal;
  font-weight: normal;
  src: local('Campton Thin'), url('./assets/fonts/CamptonThin.woff') format('woff');
}


@font-face {
  font-family: 'Campton Thin Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Campton Thin Italic'), url('./assets/fonts/CamptonThinItalic.woff') format('woff');
}


@font-face {
  font-family: 'Campton ExtraLight';
  font-style: normal;
  font-weight: normal;
  src: local('Campton ExtraLight'), url('./assets/fonts/CamptonExtraLight.woff') format('woff');
}


@font-face {
  font-family: 'Campton ExtraLight Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Campton ExtraLight Italic'), url('./assets/fonts/CamptonExtraLightItalic.woff') format('woff');
}


@font-face {
  font-family: 'Campton Light';
  font-style: normal;
  font-weight: normal;
  src: local('Campton Light'), url('./assets/fonts/CamptonLight.woff') format('woff');
}


@font-face {
  font-family: 'Campton Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Campton Light Italic'), url('./assets/fonts/CamptonLightItalic.woff') format('woff');
}


@font-face {
  font-family: 'Campton Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Campton Medium'), url('./assets/fonts/CamptonMedium.woff') format('woff');
}


@font-face {
  font-family: 'Campton Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Campton Medium Italic'), url('./assets/fonts/CamptonMediumItalic.woff') format('woff');
}


@font-face {
  font-family: 'Campton SemiBold';
  font-style: normal;
  font-weight: normal;
  src: local('Campton SemiBold'), url('./assets/fonts/CamptonSemiBold.woff') format('woff');
}


@font-face {
  font-family: 'Campton SemiBold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Campton SemiBold Italic'), url('./assets/fonts/CamptonSemiBoldItalic.woff') format('woff');
}


@font-face {
  font-family: 'Campton Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Campton Bold'), url('./assets/fonts/CamptonBold.woff') format('woff');
}


@font-face {
  font-family: 'Campton Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Campton Bold Italic'), url('./assets/fonts/CamptonBoldItalic.woff') format('woff');
}


@font-face {
  font-family: 'Campton ExtraBold';
  font-style: normal;
  font-weight: normal;
  src: local('Campton ExtraBold'), url('./assets/fonts/CamptonExtraBold.woff') format('woff');
}


@font-face {
  font-family: 'Campton ExtraBold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Campton ExtraBold Italic'), url('./assets/fonts/CamptonExtraBoldItalic.woff') format('woff');
}


@font-face {
  font-family: 'Campton Black';
  font-style: normal;
  font-weight: normal;
  src: local('Campton Black'), url('./assets/fonts/CamptonBlack.woff') format('woff');
}


@font-face {
  font-family: 'Campton Black Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Campton Black Italic'), url('./assets/fonts/CamptonBlackItalic.woff') format('woff');
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


body {
  font-family: 'Montserrat', sans-serif;
}

a {
  cursor: pointer;
}

.bg-blue {
  background: #003F63;
}

.form-control {
  height: 3.188rem;
  font-size: 1rem;
  border-radius: 0.375rem;
}

.form-group {
  margin-bottom: 0.625rem;

}

.bg-blue {
  background: #003F63;
}

.pwd_bx {
  position: relative;
}

.pwdToggle {
  position: absolute;
  right: 15px;
  top: 20%;
}

.pwdToggle svg .hide {
  fill: #D9D9D9;
}

.pwdToggle svg {
  width: 18px;
  height: 18px;
  fill: #D9D9D9;
}

.sl-select {
  position: relative;
}

.sl-select:after {
  content: '';
  position: absolute;
  right: 10px;
  top: 17px;
  background: #fff url(./assets/img/down-arrow.svg) no-repeat;
  background-size: contain;
  z-index: 1;
  width: 18px;
  height: 18px;
  pointer-events: none;
}

.w100 {
  flex: 0 0 100% !important;
  max-width: 100% !important;
  width: 100% !important;
}

.form-control {
  background: #fff !important;
  padding: 0.6rem 1.25rem !important;
}


.google-btn {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}


.content {
  background-color: #fff;
  display: flex;
  justify-content: center;
  padding: 2rem;
  width: 400px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e1e1e1;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: #0089cc;
  transition: 0.4s;
}

.sl-switch input:checked+.slider {
  background-color: #c7e5f4;
}

.sl-switch input:focus+.slider {
  outline: none;
}

.sl-switch input:checked+.slider:before {
  transform: translateX(16px)
}

.sl-switch .slider.round {
  border-radius: 34px;
}

.sl-switch .slider.round:before {
  border-radius: 50%;
}

.switch input:focus {
  outline: none;
  box-shadow: none;
}

.form-control:focus {
  box-shadow: none !important;
  outline: none;
}

.sl-btn {
  box-shadow: 0px 5px 6px #00000029;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.jas {
  justify-content: space-between;
}

.header .container,
.footer .container {
  padding-left: 40px;
  padding-right: 40px;
}

.sl-main-box {
  background: #fff;
  border-radius: 0.625rem;
  border: 1px solid #CCCCCC;
  margin-bottom: 3.938rem;
}

.sl-back-btn {
  font-size: 16px;
  border: 1px solid #DCDCDC;
  padding: 0.438rem 2.125rem;
  border-radius: 0.375rem;
  color: #0089CC;
}

.heading {
  border-bottom: 1px solid #D2D2D2;
  padding-bottom: 1.063rem;
}

button:active {
  transform: matrix(0.97, 0, 0, 0.97, 0, 0);
  transition-duration: .2s;
}

.sl-main-heading h3 {
  font-size: 1.5rem;
  font-family: 'Campton Bold';
  color: #003F63;

}

.sl-main-heading {
  padding: 2rem;
}

.rating_box .sl-icon {
  display: inline-block;
  margin-right: 5px;
}

.rating_box .sl-icon svg {
  width: 15px;
  height: 15px;
}

.rating_box .gray svg path {
  fill: #B4AFAF !important;
}


.calendar_box {
  width: 23.75rem;
  border: 1px solid #C7D1DE;
  border-radius: 4px;
  padding: 13px 22px;
  height: 29rem;
}

/* .height-box{
  height: 29rem;
} */
.calendar_box h3 {
  font-size: 1.125rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  color: #0089CC;

}

.DayPicker {
  width: 100% !important;
}

.DayPicker-Month {
  margin: 1rem 0 !important;
  width: 100%;
}

.DayPicker-Day {
  border-radius: 8px !important;
  color: #505050 !important;
}

div.DayPicker-Day.DayPicker-Day--today {
  font-weight: 400;
}

/* .DayPicker-Day--today{
  background: #0089CC;
  color: #fff !important;
} */
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background: #0089CC !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background: #0089CC !important;
}

.DayPicker-Caption>div {
  font-family: 'Campton Bold' !important;
  color: #505050 !important;
}

.DayPicker-NavButton--next {
  background: url(./assets/img/calendar-down-arrow.svg)no-repeat !important;
  background-size: contain;
  width: 24px !important;
  height: 25px !important;
}

.DayPicker-NavButton--prev {
  background: url(./assets/img/calendar-up-arrow.svg)no-repeat !important;
  background-size: contain;
  width: 24px !important;
  height: 25px !important;
  margin-right: 2.5em !important;
}

.DayPicker-NavButton {
  right: 5px !important;
}

.DayPicker-Weekday {
  color: #0089CC !important;
  font-size: 16px !important;
}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  color: #505050 !important;
}

.timeTable .sl-scroll {
  min-height: 50px;
}

.timeSlot a {
  font-size: 15px;
  color: #003F63;
  border: 1px solid #C7D1DE;
  border-radius: 6px;
  padding: 9px;
  display: inline-block;
  margin-right: 10px;
  line-height: 1.125rem;

}

.timeSlot a.active {
  background: #0089cc;
  color: #fff;
}

.timeSlot a:hover {
  color: #0089CC;
}

.timeTable h4 {
  font-size: 16px;
  font-family: 'Campton Bold';
  color: #505050;
  vertical-align: middle;
  margin-bottom: 5px;
}

.timeSlot {
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0.875rem;
}

.avail_slot .timeSlot {
  margin-bottom: 0 !important;
}

.avail_slot .timeTable {
  margin-top: 7px;
}



.sl-radio-btn [type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.sl-radio-btn [type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #505050;
  font-size: 1.125rem;
}

.sl-radio-btn [type="radio"]:checked+label:before,
[type="radio"]:not(:checked)+label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 23px;
  height: 23px;
  border: 4px solid #C8E8F8;
  border-radius: 100%;
  background: #fff;
  border: 1px solid #0089CC !important;
}

.sl-radio-btn [type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
  content: '';
  width: 15px;
  height: 15px;
  background: #0089CC;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.sl-radio-btn [type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.sl-radio-btn [type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.sl-radio-btn {
  display: flex;
  padding: 1.5rem 0;
}

.sl-radio-btn .sl-radio {
  margin-left: 30px;
}

.DayPicker-Day--disabled {
  opacity: .3;
}

.sl-choosefile label span {
  font-size: 13px;
  color: #7a8489;
}

.sl-choosefile label {
  border: 1px solid #CCCCCC;
  border-radius: 6px;
  height: 3.188rem;
  line-height: 3rem;
  width: 100%;

}


@media (max-width:1520px) {
  .avail_time_bx .days label {
    width: 38px;
  }

  .sl-select:after {
    top: 13px;
  }
}

.sl-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
  font-size: 14px;
  color: #0089CC;
}



.spinner-border span {
  display: none;
}

.spinner-border.text-success {
  color: #0089CC !important;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: fixed;
  margin: 0 auto;
  width: 100%;
  z-index: 9999;
  background-color: #00000091;
  top: 0;
  left: 0;

}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  margin: 0;
}

.sl-edit-btn {
  width: 88px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #DCDCDC;
  font-size: 16px;
  color: #0089CC;
}

.np23 {
  padding: 23px;
}

@media only screen and (max-width: 1520px) {
  html {
    font-size: 82%;
  }

}

@media (max-width : 767px) {
  .sl-sign-btn {
    width: 100% !important;
  }

  .signUpClient.bg-blue {
    background: #fff !important;
  }

  .sl-box .form_bx {
    max-width: 100% !important;
  }

  .sl-select:after {
    top: 12px;
  }

  .calender-order {
    order: -1;
  }

  .mobile-margin-0 {
    margin: 0 !important;
  }

  .mobile-ml-16 {
    margin-left: 16% !important;
  }

  .overflow-scrollbar {
    overflow-x: auto
  }


  /* swiftlaw layer resposive detail  */


  .form_head .steps:after {
    width: 80% !important;
  }

  .avail-modal {
    max-width: 100%;
  }

  .avail-modal .days {
    justify-content: space-around;
  }

  .all-appointment .nav-tabs {
    width: 100%;
  }

  .menu_box .flex {
    flex-wrap: nowrap;
  }

  .all-appointment .nav-tabs {
    width: 100% !important;
    height: auto !important;

  }

  .sub-tabs .nav.nav-tabs .nav-item {
    flex: 0 0 25%;
    max-width: 33.33% !important;
  }

  .all-appointment .nav-tabs .nav-item {
    flex: 0 0 100% !important;
  }

  .all-appointment .nav-tabs:after {
    width: 0 !important;
  }

  .all-appointment .nav-tabs:after {
    width: 0 !important;
  }

  .tabs_grid {
    padding-left: 0px !important;
    padding-right: 0 !important;
  }

  .view_booking_details .sl-cancel-btn {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
    font-size: 14px !important;
  }

  .btns a {
    width: auto !important;
  }

  .sub-tabs .nav.nav-tabs {
    border-bottom: none !important;
  }

  .sub-tabs {
    margin-top: 1rem;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .all-appointment {
    background: #fff;
    border-radius: 0.625rem;
    padding-top: 1.5rem !important;
    border: none !important;
    margin-bottom: 1rem !important;
    padding-bottom: 5px !important;
  }

  .button_box button {
    width: 100% !important;
  }

  .button_box {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .calendar_box {
    padding: 8px 10px !important;
  }

  .all-appointment .nav-tabs .nav-link {
    font-size: 14px !important;
    font-family: 'Campton Medium' !important;
    padding: 7px !important;
  }

  .view_booking_details .view_box h3 span {
    font-family: "Campton Medium";
    font-size: 16px !important;
  }

  .view_booking_details .view_box .sl-back-btn {
    font-size: 14px !important;
  }

  .client-details h3 {
    font-size: 16px !important;
    font-family: "Campton semibold" !important;
  }

  .fresh-appt {
    font-size: 14px !important;
  }

  .client-details .client_info label {
    font-size: 15px !important;
    font-family: "Campton semiBold" !important;
  }

  .client-details .client_info .item {
    margin-bottom: 4px !important;
  }

  .client-details .client_info {
    margin-top: 4px !important;
  }

  .rele_Doc {
    margin-top: 1rem !important;
  }

  .rele_Doc h3 {
    font-size: 16px !important;
    font-family: "Campton semiBold" !important;
  }

  .reschedule_slot h3 {
    font-size: 16px !important;
    font-family: "Campton semiBold" !important;
  }

  .client-details .client_info p {
    font-size: 13px !important;

  }

  .book_box span {
    font-size: 13px !important;
  }

  .book_box .sl-view-btn {
    font-size: 14px !important;
  }

  .book_box h4 {
    font-size: 15px !important;
    font-family: "Campton semiBold" !important;
  }

  .avtar {
    width: 2rem !important;
    height: 2rem !important;
  }

  .calendar_box h3 {
    font-size: 15px;
    font-family: 'Campton semiBold';
  }

  .DayPicker-Caption>div {
    font-family: 'Campton semiBold' !important;
    font-size: 15px !important;
  }

  .timeTable h4 {
    font-size: 15px !important;
    font-family: 'Campton semiBold' !important;
  }

  .button_box button {
    width: 100% !important;
  }

  .slot_box p {
    font-size: 13px !important;
  }

  .view_booking_details .view_box .flex .item {
    max-width: 70% !important;
  }

  .view_booking_details .view_box .flex {
    flex-wrap: nowrap !important;
  }

  .view_booking_details .view_box .heading {
    padding-bottom: 8px !important;
  }

  .proceed-box {
    padding: 0.438rem 1rem;
    border-radius: 0.625rem;
    margin-top: 1.375rem;

  }

  .proceed-box h4 {
    font-size: 15px;
    color: #0089cc;
    font-weight: 600;
  }

  .lawyer-list .filter_box h4 {
    font-size: 16px !important;
  }

  .lawyer-list .book_box {
    padding: 10px !important;
    border: none !important;
  }

  .lawyer-list .book_box p {
    font-size: 13px;
  }

  .sl-icon svg {
    height: 14px;
    width: 12px;
  }

  .lawyer-list .book_box .sl-price-btn {
    font-family: "Campton semiBold";
    padding: 0px 4px !important;
    border-radius: 4px;
    border: none;
    font-size: 13px !important;
  }

  .rating-item .sl-icon svg {
    width: 15px !important;
    height: 15px !important;
  }

  .sidebar-menu .side_navbar a {
    font-size: 14px !important;
  }

  .sl-main-box {
    border: none;
    margin-bottom: 1.5rem !important;
  }

  .book_box .sl-price-btn {
    font-family: "Campton semiBold" !important;
    padding: 4px 6px !important;
  }

  .view_booking_details .view_box {
    border-bottom: none !important;
  }

  .notifications .sl-main-box {
    padding: 0 5px !important;
  }

  .notifications .notiBox .notifData p {
    padding-bottom: 2px !important;
    font-size: 13px !important;
  }

  .notifications .notiBox .notifData {
    padding: 10px 7px !important;
  }

  .notifications .notiBox {
    margin-top: 10px;
    padding: 7px 10px !important;
  }

  .sl-main-heading {
    padding: 1rem !important;
  }

  .notifications .notiBox h5 {
    padding-bottom: 0px !important;
  }

  .notiWappper {
    max-height: 250px !important;
  }

  .bookings-details-btns a {
    width: auto !important;
  }

  .sl-main-heading {
    padding-top: 0px !important;
  }

  .lawyerTC .sl-main-box {
    padding-top: 0px !important;
  }

  .lawyerTC .tnc_box {
    padding: 0rem 0 !important;
  }

  .heading {
    padding-bottom: 0.3rem;
  }

  .sl-main-heading h3 {
    font-size: 20px !important;
    font-family: 'Campton semiBold' !important;
  }

  .faq_box .sl-main-box {
    padding-top: 0px !important;
  }

  .faq_box .accordion_box {
    padding: 0px 0 !important;
  }

  .faq_box .accordion-header h4 {
    font-size: 14px;
    font-weight: 600 !important;
  }

  .faq_box .acc_box p {
    font-size: 13px !important;
    line-height: 17px !important;
  }

  .faq_box .accordion-header {
    padding: 7px 30px;
  }

  .heading {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .faq_box .accordion-header {
    padding: 7px 15px 10px 0;
  }

  .my_profession .heading h3 {
    font-size: 18px !important;
    font-family: "Campton semiBold" !important;
  }

  .lawyer_profile .profile_form {
    margin: 0px auto;
  }

  .form_box .textarea {
    margin-top: 0rem !important;
  }

  .firm_box label {
    font-size: 15px !important;
    margin-bottom: 5px !important;
  }

  .sl-edit-btn {
    width: 60px;
    height: 29px;
    font-size: 14px;
    line-height: 12px;
  }

  .newService .form-group {
    margin-right: 5px !important;
  }

  .lawyer_profile .profile_form {
    margin: 5px auto !important;
  }

  .my_profession .sl-main-box {
    padding-top: 0px !important;
  }

  .lawyer_profile .profile_form label {
    font-size: 15px;
  }

  .lawyer_profile .lawyer_photo_box {
    margin-bottom: 0rem !important;
  }

  .changePassword .chng_pwd_form {
    margin-top: 1rem !important;
  }

  .changePassword .chng_pwd_form label {
    font-size: 15px !important;
    margin-bottom: 3px !important;
  }

  .changePassword .chng_pwd_form .pwd_box .form-group {
    margin-bottom: 10px !important;
  }

  .service_box h4 {
    font-size: 22px !important;
    font-family: "Campton semiBold" !important;
    line-height: 22px !important;
    margin: 5px 0 8px !important;
  }

  .howWorks .howWorks-info h3 {
    font-size: 20px !important;
    font-family: "Campton semiBold";
  }

  .topLawyer h3 {
    font-size: 20px !important;
    font-family: "Campton semiBold";
  }

  .topLawyer .lawyer_box {
    margin-top: 0px !important;
  }

  .about .about_info {
    padding: 10px;
  }

  .about .about_info h3 {
    font-size: 20px !important;
    font-family: "Campton semiBold";
  }

  .lawyer_details .sl-main-box {
    padding-top: 0rem !important;
  }

  .payment_method .sl-main-box {
    min-height: 17.7rem !important;
  }

  .payment_method .sl-main-box h2 {
    padding-bottom: 0.5rem !important;
    font-size: 20px !important;
    font-family: "Campton semiBold" !important;
  }

  .card_form .save-btn {
    font-size: 14px !important;
  }

  .sl-radio-btn [type="radio"]:checked+label,
  [type="radio"]:not(:checked)+label {
    font-size: 14px !important;
  }

  .sl-radio-btn {
    padding: 1rem 0 !important;
  }

  .profile .sl-main-box {
    padding: 0.75rem 0 !important;
  }

  .payment_method .cards_list {
    padding: 1.563rem 0 !important;
  }

  .calendarWithslot {
    margin-top: 2rem;
  }

  .regular__avail .jas {
    justify-content: inherit !important;
  }

  .timeFlex {
    flex-wrap: nowrap !important;
  }

  .regular__avail h3 {
    font-size: 18px !important;
    font-family: "Campton semiBold" !important;
  }

  .excluBx .jas {
    justify-content: space-between !important;
  }

  .about-Us .sl-main-box {
    padding-top: 0px !important;
  }

  .about-Us .about_content {
    padding: 0 !important;
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }

  .contact-us .sl-main-box {
    padding-top: 0 !important;
  }

  .contact-us .contact_form {
    padding: 0rem 0;
  }

  .addQuotationDetails .client_form {
    max-width: 100%;
    margin-top: 10px !important;
  }

  .view_booking_details .sl-cancel-btn {
    width: 100% !important;
  }

  .sl-icon svg {
    width: 14px !important;
    height: 20px !important;
  }

  .lawyer_details .year_box .flex .exp p {
    font-size: 13px !important;
  }

  .footer .social_links {
    margin-top: 1rem !important;
    margin-bottom: 1.5rem !important;
  }

  .sl-main-heading {
    padding-bottom: .5rem !important;
  }

  .header {
    padding-bottom: 0.5rem;
  }

  .footer {
    padding-top: 1rem !important;
  }

  .faq_box .accordion-header {
    padding: 7px 15px !important;
  }

  .book_box .lawyer_pic {
    margin-bottom: 0px !important;
  }

  .appt-cancelled {
    padding-bottom: 0px !important;
  }

  .view_booking_details .view_box .flex .item {
    flex: 0 0 auto !important;
    width: auto !important;
  }

  .all_rele_doc {
    margin-bottom: 20px !important;
    margin-top: 5px !important;
    ;
    margin-left: 12px !important;
  }


}

@media (min-width:768px) {
  .overflow-scrollbar {
    overflow-x: auto
  }
}

@media (max-width : 1024px) and (min-width:768px) {

  .calender-order {
    order: -1;
  }

  .overflow-scrollbar {
    overflow-x: auto
  }




}

@media (max-width : 991px) and (min-width:768px) {
  .lawyer-list .sub-flex .sub-item {
    width: 100% !important;
  }

  .main-flex-box {
    display: flex;
    flex-wrap: wrap !important;
  }

  .lawyer-list .sub-flex .sub-item {
    width: 45% !important;
  }

  .view__box {
    width: 100% !important;
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }



}


@media (max-width :767px) and (min-width:480px) {
  .form_head .steps:after {
    width: 50% !important;
  }

  .btn_wh button {
    width: 9rem !important;
  }

  .bookings-details-btns a {
    width: auto !important;
  }



}

@media (max-width :340px) {
  .book_box {
    width: auto !important;
  }

  .sub-tabs .nav.nav-tabs .nav-item .nav-link {
    font-family: "Campton Book";
    font-size: 14px;
  }

  .bookings-details-btns a .sl-btn {
    width: 100% !important;
  }

  .view_booking_details .sl-cancel-btn {
    margin-bottom: 10px !important;
    width: 100% !important;
  }



}


.rc-time-picker {
  margin-right: 10px;
}

.slick-list {
  margin: 0 -10px;
}

.slick-slide>div {
  padding: 0 10px;
}

.data-not-found {
  width: 100%;
  text-align: center;
  margin-top: 35px;
}

.data-not-found p {
  font-size: 18px;
  font-weight: bold;
  color: #003F63;
}

.data-not-found .sl-icon {
  margin-bottom: 10px;
  display: inline-block;
}

.pagination {
  border: 1px solid #ccc;
  border-radius: 4px;
  display: inline-flex !important;
}

.pagination li {
  padding: 0;
  margin: 0;
  border-right: 1px solid #ccc;
  text-transform: capitalize;
}

.pagination li a {
  padding: 8px 15px;
  display: inline-block;
}

.pagination li:last-child {
  border-right: none;
}

.pagination li.disabled a {
  color: #8A8A8A !important;
}

.paginationBx {
  padding: 12px;
  margin-left: 22px;
  margin-top: 30px;
}

.pagiWapper {
  text-align: right;
}

.countryCode {
  width: 77px;
  position: relative;
}

.phone_wapper .phone_bx {
  border: 1px solid #ced4da;
  border-radius: .25rem;
}

.phone_wapper .phone_bx .form-group {
  margin-bottom: 0;
}

.phone_wapper {
  margin-bottom: 0.625rem;
}

.phone_wapper .phone_bx .form-control {
  border: none;
}

.countryCode:before {
  content: '';
  position: absolute;
  top: 5px;
  bottom: 5px;
  height: 80%;
  width: 1px;
  background: #E3E3E3;
  right: 0;
}

.sl-blue-btn {
  width: 21.313rem;
  height: 3.188rem;
  background: #0089CC;
  border: none;
  border-radius: 6px;
  color: #fff;
}

.swal-title {
  font-size: 18px;
}

.DayPicker-Day.DayPicker-Day--selected {
  color: #fff !important;
}

.css-1s2u09g-control,
.css-1pahdxg-control {
  min-height: 42px !important;
  width: 110px !important;
}

.css-1s2u09g-control {
  border: none !important;
}

.css-1okebmr-indicatorSeparator {
  display: none;
}

.css-qc6sy-singleValue {
  text-overflow: inherit !important;
  margin-right: 0 !important;
}

.countryCode {
  width: 32%;
  position: relative;
}

.countryCode:before {
  content: "";
  position: absolute;
  top: 5px;
  bottom: 5px;
  height: 80%;
  width: 1px;
  background: #e3e3e3;
  right: 0;
}

.w75 {
  max-width: 75% !important;
  width: 100%;
}

.cvv-box {
  max-width: 40%;
  margin-left: auto;
  margin-bottom: 0;
  margin-top: 10px;
}

.payment_method.payment_card_list .cards_list .sl-radio-btn [type=radio]:checked+label:before,
.payment_method.payment_card_list .cards_list [type=radio]:not(:checked)+label:before {
  top: 20px;
}

.payment_method.payment_card_list .cards_list .sl-radio-btn [type=radio]:checked+label:after,
.payment_method.payment_card_list .cards_list [type=radio]:not(:checked)+label:after {
  top: 24px;
}

.profile .profile_photo figure {
  text-align: center !important;
}

.profile .profile_photo figure img {
  width: 100%;
}

.profile .sl-choose-file {
  cursor: pointer;
}

.timeFlex {
  align-items: center;
}

.yourAvailBx {
  padding-top: 0rem !important;
}

.cn-icon {
  line-height: 12px !important;
}

.service_info p {
  color: #505050 !important;
}

.service_box .all_service .flex .item {
  justify-content: center !important;
}

.about_info h3 {
  margin-bottom: 10px !important;
}

.howWorks-info ol li {
  margin-bottom: 15px;
}

.howWorks-info h5 {
  margin-bottom: 5px;
}