@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.signUpClient {
    position: relative;

    &::before {
        content: '';
        background: url(../../assets/img/login-bg-pattern.jpg);
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 68vh;
    }

    .sl-box {
        z-index: 1;
    }
}

.signUpClient.bg-blue {
    background: #0089CC;
}

.service_banner {
    background: url(../img/service-banner.png);
    background-size: cover;
    border-radius: 6px;
    height: 27.188rem;
    position: relative;
    color: #fff;
    padding: 3.438rem;

    .service_caption {
        position: absolute;
        bottom: 3.438rem;
        left: 3.438rem;
        max-width: 37.5rem;
        font-family: 'Montserrat', sans-serif;

        h1 {
            font-size: 2rem;
            font-weight: 600;
        }

        p {
            font-size: 1.1rem;
        }
    }
}

.service_box .all_service .flex .item {
    height: 16.688rem;
    width: 100%;

    .item-caption {
        width: 100%;
        height: 100%;
    }
}

.all_service {
    .flex {
        .item {
            transition: 0.6s;
            // &:hover{
            //     background: #0089CC !important;
            //     transform: scale(1.1);
            //     border-color: #0089CC !important;
            //     // transition: 0.2s;
            //     color: #fff !important;
            //     border-radius: 5px !important;
            // }
        }
    }
}

.reliable-box {
    padding: 7.688rem 6rem;

    .flex {
        align-items: center;
    }

    figure {
        width: 22.25rem;
        height: 22.25rem;
        border-radius: 22.25rem;
        overflow: hidden;
        flex: 0 0 30%;
        max-width: 30%;
    }

    .content_bx {
        margin-left: 5rem;

        h2 {
            font-size: 3.875rem;
            color: #003F63;
            font-weight: 600;
            font-family: 'Montserrat', sans-serif;
            line-height: 4.313rem;
        }

        p {
            font-size: 1.125rem;
            color: #4B4B4B;
        }

        ul {
            margin: 1.375rem 0 0 0;
            padding: 0;

            li {
                list-style: none;
                font-size: 1.125rem;
                color: #4B4B4B;
                line-height: 24px;
            }
        }

    }
}

.item.lawyer_info {
    background: #CEE4F1;
    border-radius: 9px;
    height: 100%;
    border: 1px solid #C7DBE6;
    overflow: hidden;
}

.topLawyer {
    .lawyer_pic {
        text-align: center;
        height: 12.313rem;
        padding-top: 13px;

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }

    .slick-slide>div {
        padding: 0 1.313rem;
    }

    .lawyer_box {
        .sl-icon {
            width: 23px;
            display: inline-block;
            margin-right: 10px;
            text-align: center;
        }
    }

}

.footer.client_footer {
    background: #EFEFEF;
    color: #4B4B4B;

    .flex {
        .item:first-child {
            flex: 0 0 32%;
            max-width: 32%;
        }
    }

    .footer_logo {
        width: 100%;

        figure {
            width: 20.438rem;
        }
    }

    .copy-right {
        background: transparent;
        border-top: 1px solid #CECECE;
        margin-top: 2.813rem;
        text-align: left;
        margin-left: -30px;
        margin-right: -30px;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;

        p {
            font-size: 16px;
            line-height: normal;

            span {
                b {
                    color: #003F63;
                }
            }

            i {
                font-size: 13px;
            }
        }

        .flex {
            justify-content: space-between;

            .socialMedia {
                display: flex;
                align-items: center;

                a {
                    display: inline-block;
                    margin: 0 10px;

                    svg {
                        width: 20px;
                        height: 20px;
                        fill: #5b708a;
                    }
                }
            }
        }
    }

    h4 {
        font-size: 1.5rem;
        font-weight: bold;
        font-family: 'Montserrat', sans-serif;
        margin-bottom: 11px;
    }

    a {
        font-size: 16px !important;
        color: #003F63 !important;
        line-height: 22px !important;
    }

    p {
        font-size: 16px !important;
        color: #4B4B4B !important;
    }

    .copyRight_content {
        flex: 0 0 70%;
        max-width: 70%;
    }

    .btn-make-enquire {
        font-size: 16px;
        font-weight: bold;
        color: #fff !important;
        width: 19.125rem;
        background: #0089CC;
        border-radius: 6px;
        text-align: center;
        padding: 1rem;
        margin-top: 14px;
    }

    .btn-call {
        background: #CEE4F1;
        font-size: 16px;
        font-weight: bold;
        color: #0089CC !important;
        width: 19.125rem;
        border-radius: 6px;
        text-align: center;
        padding: 1rem;
        margin-top: 14px;
    }
}

.lawyer-list {
    .top-heading {
        padding: 2.938rem 0;

        h1 {
            font-size: 1.875rem;
            color: #003F63;
            font-weight: 600;
        }

        p {
            font-size: 16px;
            color: #919191;
        }
    }

    .main-flex-box {
        .sl-main-box {
            border-radius: 0;
            margin-right: 2.469rem;
            flex: 0 0 100%;
            max-width: 20rem;
            padding: 14px !important;

            .sl-blue-box {
                border-radius: 0;
                border-bottom: 1px solid #C7D1DE;
                background: #fff;

                .exp_box {
                    border-radius: 0;
                    padding: 15px 0;
                }
            }
        }
    }

}

.home {
    .header::after {
        height: 31rem;
    }
}

.input-range__slider {
    background: transparent linear-gradient(180deg, #0089CC 0%, #003F63 100%) 0% 0% no-repeat padding-box !important;
    height: 1.063rem !important;
    width: 0.438rem !important;
    border: none !important;
    border-radius: 0 !important;
}

.input-range__track {
    background: #DBDBDB;
    border-radius: 0 !important;
    height: 0.438rem !important;
}

.input-range__slider {
    margin-left: -2px !important;
    margin-top: -0.76rem !important;
}

.input-range__label--value {
    left: -3px !important;
}

.sl-radio-btn [type="radio"]:not(:checked)+label:after {
    background: #CED2D5;
    opacity: 1;
    transform: scale(1);
}

.rating-item .sl-radio-btn [type=radio]:checked+label:before,
.rating-item [type=radio]:not(:checked)+label:before {
    border-width: 1px !important;
    border-color: #0089CC !important;
}

.sl-radio-btn [type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
    background: #003F63;
}

.rating-item .sl-radio-btn [type=radio]:checked+label:after,
.rating-item [type=radio]:not(:checked)+label:after {
    width: 14px !important;
    height: 14px !important;
    top: 3px !important;
    left: 3px !important;
}

.lawyer-list .sl-blue-box {
    padding: 6px !important;
}

.filter_box {
    .white-bx {
        padding: 0 !important;
    }
}

.item.lawyer_list {
    border-top: 1px solid #C7D1DE !important;
}

.showing_data {
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    color: #003F63;
    padding-top: 2.938rem;
    padding-bottom: 2.125rem;

    span {
        font-weight: bold;
    }
}

.lawyer_list {
    .book_box {
        padding: 0 !important;

        .lawyer_pic {
            width: 100% !important;
            height: 12.313rem !important;
            border-radius: 0 !important;
            background: #CEE4F1;
        }
    }

    .content_bx {
        padding: 1.123rem 1.375rem;

    }

    .rating_box {
        border-bottom: 1px solid #C7DBE6;
        padding-bottom: 0.938rem;

        .react-stars {
            span {
                line-height: 20px;
                display: inline-block;
            }
        }
    }

}

.lawyerName {
    h4 {
        line-height: 22px;
        padding: 0 !important;
    }

    p {
        font-size: 1.12rem !important;
        color: #4B4B4B;
        padding: 0;
        line-height: 18px !important;
    }
}

.sl_opt {
    &:hover {
        background: #0089CC !important;

        p {
            color: #fff !important;
        }

        svg {
            fill: #fff !important;

            path {
                fill: #fff !important;
            }
        }
    }
}

.sl-main-box.b-0 {
    border: none;
}

.lawyer_detail_box.slot_lawyer_info {
    background: transparent linear-gradient(180deg, #E8E8E8 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    border: none !important;
    margin: 0 10px !important;
    padding: 1.875rem 5rem;
    padding-bottom: 0 !important;

    .flex {
        .box {
            &:last-child {
                flex: 0 0 100%;
                max-width: 85%;
            }
        }
    }

    .dropDown_bx {
        max-width: 47.563rem;
        margin: 1.875rem auto;
    }
}

.service_box .all_service .flex .item {
    transition: all .2s;

    &:hover {
        border-color: #0089CC;
        // transition: all .3s;
        transform: scale(1);
        // box-shadow: 0 0 4px rgba(0,0,0,.3);
    }
}

.DayPicker-Day {
    border-radius: 2px !important;
    padding: 1rem 0.5em !important;
}

.DayPicker-Day--today {
    // background: #0093E9 !important;
    // color: #fff !important;
    font-weight:0;

}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background: #0093E9 !important;
}

.DayPicker-Caption {
    text-align: center !important;
}

.DayPicker-NavButton--prev {
    left: 0 !important;
    transform: rotate(-90deg);
    margin: 0 !important;
}

.DayPicker-NavButton--next {
    transform: rotate(-90deg);
}

.bb-1 {
    border-bottom: 1px solid #C7DBE6 !important;
}

.tabs_grid {
    .book_box {
        width: 100% !important;
    }
}

.book_box {
    padding: 0 !important;
    overflow: hidden;
    border-color: #C7DBE6 !important;

    .lawyer_pic {
        height: 12.313rem !important;
        width: 100% !important;
        border-radius: 0 !important;
    }

    .content_bx {
        padding: 1.25rem 1.375rem !important;

        .lawyerName {
            p {
                font-size: 1.25rem;
                padding: 0;
            }
        }

        .flex.jas {
            padding-bottom: 7px;
        }

        .sub_content {
            padding-top: 1.375rem;

            .sl-icon {
                max-width: 23px;
                margin-right: 11px;
                margin-bottom: 0.75rem;
            }
        }
    }

    .lawyer_pic {
        padding-top: 10px !important;
        background: #CEE4F1;

        img {
            object-fit: contain !important;
        }
    }

    .sl-view-btn {
        width: 100% !important;
        height: 3rem !important;
        border-radius: 4px !important;
        box-shadow: none !important;
        background: #003F63 !important;
        font-size: 16px !important;
    }
}

.all-appointment .nav-tabs {
    width: 100% !important;
}

.lawyer_box {
    p {
        .sl-icon {
            width: 23px !important;
            margin-right: 11px !important;
            text-align: center !important;
            display: inline-block !important;
        }
    }
}

.book_box .sl-view-btn {
    height: 3rem !important;
    line-height: 3rem !important;
    padding: 0 !important;
}

.book_box h4 {
    font-weight: 600 !important;
    color: #0089CC !important;
}

.book_box {
    .content_bx {
        p {
            .sl-icon {
                width: 23px !important;
                text-align: center !important;
                margin-right: 11px !important;

            }
        }
    }

    .sl-price-btn {
        font-size: 13px !important;
        font-weight: 600 !important;
        color: #003F63 !important;
    }
}
.avail_head {
    background: transparent linear-gradient(180deg, #E8E8E8 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px 10px 0px 0px;
    text-align: center;
    margin-top: 3.125rem;
    padding: 2.5rem;

    h1 {
        font-size: 2rem;
        color: #003F63;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
    }

    p {
        font-size: 16px;
        color: #4B4B4B;
        font-family: 'Montserrat', sans-serif;
    }
}

.yourAvail__box {
    padding: 0 15px;

    .sl-main-box {
        border-radius: 0;
        padding: 1.125rem;
        // height: calc(100% - 3.938rem);
    }

    .col-xxl-9 {
        padding-right: 7px;
    }

    .col-xxl-3 {
        padding-left: 7px;
    }
}

.regular__avail.excluBx {
    margin-top: 0.438rem;
}

.exclusion_calendar {
    .DayPicker-Caption {
        text-align: left !important;
    }

    .DayPicker-NavButton--prev {
        right: 10% !important;
        top: 1.1rem !important;
        left: auto !important;
    }

    .DayPicker-Weekday {
        font-weight: 600;
        padding: 0px 4px !important;
    }

    .DayPicker-Day {
        font-size: 16px;
        font-weight: 600;
        padding: 0.7rem 0.5em !important;
    }

    .DayPicker-Day--today {
        background: #FFFFFF 0% 0% no-repeat padding-box !important;
        box-shadow: 0px 2px 9px #00000042;
        border-radius: 6px !important;
        color: #0089CC !important;
    }
}

.view_booking_details {
    .view_box.view_detail_box {
        .book_box.mb_zero {
            padding: 1.4rem !important;

            .lawyer_pic {
                width: 4.125rem !important;
                height: 4.125rem !important;
                border-radius: 4.125rem !important;
            }
        }
    }

}

.eventList {
    table {
        border-collapse: collapse;

        tr {
            th {
                padding: 2.063rem 0 !important;
            }

            td,
            th {
                border: 1px solid #C7D1DE;
                // width: 14.2857142857%;
                height: 3.5rem; // 4.125rem;
                text-align: center;
                padding: 0;

                &:first-child {
                    border-left: none;
                    // padding-left: 5px;
                }

                &:last-child {
                    border-right: none;
                    // padding-right: 5px;
                }
            }

            td {
                span {
                    display: table;
                    padding: 0;
                    color: #fff;
                    // margin-bottom: 10px;
                    font-size: 11px;
                    padding: 5px;
                    font-family: 'Montserrat', sans-serif;
                    border-radius: 2px;
                    width: 100%;
                    &.availability {
                        background: #0089CC;
                    }
                    &.unavailability {
                        background: #CC004C;
                    }
                }
            }
        }
    }

}

.font-11{
    font-size: 11px !important;
}



@media (max-width:1520px) {
    .lawyer-list .filter_box {
        flex: 0 0 100% !important;
        max-width: 20rem !important;
    }

    .service_banner {
        height: 25rem;
    }

    .reliable-box .content_bx h2 {
        font-size: 3.2rem;
        line-height: 3.2rem;
    }

    .book_box p {
        font-size: 14px !important;
    }
}

@media (max-width:991px){
    .reliable-box{
        padding: 10px;
        figure{
            width: 100%;
            height: 100%;
            margin: 0 auto;
            flex: 0 0 100%;
            max-width: 90%;
        }
        .content_bx{
            margin-left: 0;
            margin-top: 1.5rem;
            h2{
                font-size: 2.2rem;
                line-height: 2.4rem;
                margin-bottom: 1rem;
            }
        }
    }
    .service_banner{
        height: auto;
        padding: 1.5rem;
        .service_caption{
            position: static;
            h2{
                font-size: 1.6rem;
            }
        }
    }
    .service_box{
        .all_service{
            padding-left: 0;
            padding-right: 0;
        }
    }
    .sign_Col{
        .sl-icon{
            svg{
                width: 85px !important;
                height: 85px !important;
            }
        }
    }
    .sl-box .form_bx{
        padding: 0 20px;
    }
    .signIn, .signUpClient{
        padding: 15px !important;
        border-radius: 0.625rem;
    }
    .forgot_pwd,.otpInput {
        padding: 3.688rem 15px !important;
        .form_bx{
            padding: 0 5px;
        }
        .sl-icon svg {
            width: 70px !important;
            height: 70px !important;
        }
    }
    .otpInput{
        .sl-box{
            padding: 3.688rem 15px !important;
        }
    }
    .form_bx.new_pwd{
        padding: 3.688rem 0;
        .sl-icon {
            margin-bottom: 15px !important;
            svg {
                width: 70px !important;
                height: 70px !important;
            }
        }
    }
    .signUpClient{
        .sl-box{
        padding: 3.688rem 15px !important; 
        }
    }
    .service_box{
        h2{
            font-size: 1.6rem !important;
            margin-bottom: 10px;
        }
        .all_service{
            padding-left: 0 !important;
            padding-right: 0 !important;
            .flex{
                .item{
                    flex: 0 0 100% !important;
                    max-width: 100% !important;
                    margin-bottom:15px !important;
                }
            }
        }
    }
    .reliable-box{
        .flex{
            flex-wrap: wrap;
        }
    }
    .footer.client_footer .copy-right{
        margin-left: -15px;
        margin-right: -15px;
    }
    .yourAvail__box{
        padding: 0 !important;
    }
    .eventList{
        overflow-y: auto;
    }
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;
    background-color: #4A90E1 !important;
    color: #F0F8FF !important;
    border-radius: 6px !important;
}