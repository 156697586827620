.footer{
    background: #59C4E8;
    padding-top: 2.625rem;
    .flex{
        justify-content: space-between;
    }
    .footer_logo{
        width: 20.438rem;
        figure{
            margin-bottom:10px;
            max-width: 160px;
        }
        p{
            color: #fff;
            font-size: 16px;
            line-height: 18px;
            font-family: 'Montserrat',sans-serif;
        }
    }
    .social_links{
        margin-top: 12px;
        margin-bottom: 2.5rem;
        a{
            padding-right:8px;
            .sl-icon{
                svg{
                    width: 2.188rem;
                    height: 2.188rem;
                }
            }
        }
    }
    h4{
        font-size:22px;
        color: #003F63;
        margin-bottom: 8px;
        line-height: 18px;
    }
    .footer-menu{
        a{
            display: block;
            font-size: 16px;
            color: #fff;
            font-family: 'Montserrat',sans-serif;
            line-height: 18px;
        }
    }
    h3{
        font-size: 2.5rem;
        font-family: 'Montserrat',sans-serif;
        color: #003F63;
    }
    p{
        font-size: 14px;
        color: #003F63;
    }
    .app-link{
        text-align: center;
    }
    .play-Store_link{
        margin-top: 14px;
        a{
            margin:0 2px;
        }
    }
    .copy-right{
        background: #263036;
        text-align: center;
        padding: 12px 0;
        p{
            color: #fff;
            font-size: 14px;
            font-family: 'Montserrat',sans-serif;
        }
        a{
            color: #fff;
            font-size: 14px;
            font-family: 'Montserrat',sans-serif;
        }
    }
}


@media (max-width:767px){
    .footer {
        border-top: 1px solid #D2D8DF;
        background-color: #FFFFFF;
        h3{
            font-size: 20px;
        }
        h4{
            font-size: 20px;
            font-family: 'Campton Bold';
             color: #003F63;  
        }
    .flex{
        flex-wrap: wrap;
        .item{
            flex: 0 0 100% !important;
            max-width: 100% !important;
            width: 100%;
            margin-bottom:20px;
            .btn-make-enquire, .btn-call{
                width: 100%;
            }
        }
    }


    .footer_logo{
        width: 100%;
        img{
            max-width: 170px;
        }
        p{
            color: #6C7A8B;
            font-family: 'Campton Book';
            font-size: 13px;
            line-height: 20px;
        }
        figure{
            margin-bottom: 10px;
        }
    }
    .app-link{
        margin:10px 0;
        text-align: left;
    }
    .footer-menu a{
        font-family: 'Campton Book';
        color: #6C7A8B;
        font-size: 13px;
        line-height: 20px;
    }
    .play-Store_link a{
        display: block;
        margin: 10px 0;
    }
    .social_links {
        margin-top: 1.5rem;
        margin-bottom: 2rem;
    }
    .copy-right{
        background: #fff !important;
        border-top: 1px solid #D2D8DF;
        text-align: left;
        padding: 12px;
        margin-top:25px;
        p{
            color: #6C7A8B !important;
        }
        a{
            color: #6C7A8B !important;
        }
    }
}
.header .container, .footer .container{
    padding-left: 15px;
    padding-right: 15px;
}

}
.footer-menu a{
    margin-bottom:4px;
}